import React from "react";
import "./TotalsCard.css";

const TotalsCard = (props) => {
  return (
    <div
      className={
        "totals-card-wrapper" +
        (props.linkedCard ? " totals-card-linked-card" : "")
      }
      style={props.style}
      onClick={(e) => props.handleCardClick(e)}
    >
      <h4 style={{ color: props.style.color }}>{props.title}</h4>
      <p>{props.region}</p>
      <h1>{props.total}</h1>
      {props.percentage && <h3>{props.percentage}</h3>}
    </div>
  );
};

export default TotalsCard;
