import { createStore } from "redux";

let reducer = (state, action) => {
  if (action.type === "set-user") {
    return {
      ...state,
      user: action.value,
    };
  }

  return state;
};

const ReduxStore = createStore(
  reducer,
  {
    user: null,
  },
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default ReduxStore;
