import React from "react";
import "./VendorCard.css";

const VendorCard = (props) => {
  return (
    <div className="vendor-card-wrapper">
      <div
        className="vendor-card-item-1"
        style={{ backgroundImage: "url(" + props.vendor.logoURL + ")" }}
      ></div>
      <h5 className="vendor-card-item-2">{props.vendor.region}</h5>
      <h5 className="vendor-card-item-3">{props.vendor.name}</h5>
      <h5 className="vendor-card-item-4">
        {props.vendor.totalNumberOfVendorItems}
      </h5>
      <h5 className="vendor-card-item-5">
        {props.vendor.totalNumberOfCategorizedItems}
      </h5>
      <h5 className="vendor-card-item-6">
        {props.vendor.totalNumberOfUnCategorizedItems}
      </h5>
    </div>
  );
};

export default VendorCard;
