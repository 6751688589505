let productCategoryFulhausMap = [
  // {
  //   category: "Accessories",
  //   alternate: [
  //     "Accessories",
  //     "Accessory",
  //     "Boxes & Trays",
  //     "Vases",
  //     "Bottles & Jars",
  //     "Figurines & Objects",
  //     "Planters",
  //     "Candle Holders",
  //     "Bookends",
  //     "Lanterns",
  //     "Books",
  //     "Decor Accessories",
  //     "Plants",
  //     "Vase",
  //   ],
  // },
  // { category: "Bench", alternate: ["Bench", "Benches", "Skiby"] },
  // {
  //   category: "Curtains",
  //   alternate: ["Curtains", "Curtains", "window treatment"],
  // },
  // {
  //   category: "Desk",
  //   alternate: [
  //     "Office Table",
  //     "Work Table",
  //     "Work Desk",
  //     "Office Desk",
  //     "Writing Desk",
  //   ],
  // },
  // {
  //   category: "Dresser",
  //   alternate: ["Dresser", "Dressers", "Wardrobe"],
  // },
  // { category: "Linen", alternate: ["Linen", "Linens", "Sheets"] },
  // {
  //   category: "Mirror",
  //   alternate: [
  //     "Mirror",
  //     "Mirrors",
  //     "Floor Mirror",
  //     "Full Length Mirror",
  //     "Wall Mirror",
  //     "Decorative Mirror",
  //     "Leaning Mirror",
  //     "Vanity Mirror",
  //     "Standing Mirror",
  //     "Floor Mirror",
  //     "Full Length Mirror",
  //     "Leaning Mirror",
  //   ],
  // },
  // { category: "Ottoman", alternate: ["Ottoman", "Ottomans", "poof", "pouf"] },
  // { category: "Rug", alternate: ["Rug", "Rugs", "Area Rug", "Throw Rug"] },
  // { category: "Wallpaper", alternate: ["Wallpaper", "Wallpapers"] },
  // {
  //   category: "Arm Chair",
  //   alternate: ["Arm Chair", "Armchair", "ArmChair Set", "Arm Chair Set"],
  // },
  // {
  //   category: "Artwork",
  //   alternate: [
  //     "Art Work",
  //     "Artworks",
  //     "Art Work",
  //     "Wall Art",
  //     "Wall Hanging",
  //     "Art",
  //     "Framed Art",
  //     "Posters",
  //     "Framed Posters",
  //     "Wall Decor",
  //     "Wall Décor",
  //     "Sculpture",
  //     "Statue",
  //     "Abstract",
  //   ],
  // },
  // { category: "Bar Cart", alternate: ["Bar Cart", "BarCart"] },
  // { category: "Bar Chair", alternate: ["Bar Chair", "BarChair"] },
  // { category: "Bar Stool", alternate: ["Bar Stool", "BarStool"] },
  // {
  //   category: "Bar Table",
  //   alternate: ["Bar Table", "BarTable", "Pub table", "pub set", "Bar Set"],
  // },
  // {
  //   category: "Bathroom Accessories",
  //   alternate: [
  //     "Bathroom Accessories",
  //     "BathroomAccessories",
  //     "Bathroom",
  //     "Bath",
  //   ],
  // },
  // { category: "Bed", alternate: ["Bed Set", "Beds"] },
  // {
  //   category: "Ceiling Lamp",
  //   alternate: [
  //     "Ceiling Lamp",
  //     "CeilingLamp",
  //     "Chandelier",
  //     "Pendant",
  //     "Pendant Light",
  //     "Pendant Lamp",
  //     "Ceiling Fixture",
  //   ],
  // },
  // { category: "Coffee Table", alternate: ["Coffee Table", "CoffeeTable"] },
  // {
  //   category: "Console Table",
  //   alternate: [
  //     "Console Table",
  //     "ConsoleTable",
  //     "TV Unit",
  //     "Buffet Table",
  //     "TV Stand",
  //     "TV Table",
  //     "Storage Cabinet",
  //     "Entertainment Unit",
  //     "TV Cabinet",
  //     "Media Console",
  //     "Media Cabinet",
  //   ],
  // },
  // { category: "Counter Stool", alternate: ["Counter Stool", "CounterStool"] },
  // {
  //   category: "Day Bed",
  //   alternate: [
  //     "Day Bed",
  //     "DayBed",
  //     "Lounge Bed",
  //     "Chaise Long",
  //     "Chaise Longue",
  //   ],
  // },
  // {
  //   category: "Desk Chair",
  //   alternate: [
  //     "Desk Chair",
  //     "DeskChair",
  //     "Office chair",
  //     "OfficeChair",
  //     "Drafting Chair",
  //     "Executive Chair",
  //     "Computer Chair",
  //     "Swivel Chair",
  //   ],
  // },
  // {
  //   category: "Dining Chair",
  //   alternate: [
  //     "Dining Chair",
  //     "DiningChair",
  //     "Dining Chair Set",
  //     "Dining Stool",
  //     "Side Chair",
  //   ],
  // },
  // {
  //   category: "Dining Table",
  //   alternate: [
  //     "Dining Table",
  //     "DiningTable",
  //     "Dining Table Set",
  //     "Dining Set",
  //     "Dinette Table",
  //     "Dinette Set",
  //   ],
  // },
  // { category: "Mattress - Other", alternate: ["Mattress"] },
  // {
  //   category: "Double Mattress",
  //   alternate: ["Double Mattress", "DoubleMattress"],
  // },
  // { category: "Entry Hook", alternate: ["Entry Hook", "EntryHook"] },
  // {
  //   category: "Floor Lamp",
  //   alternate: ["Floor Lamp ", "FloorLamp ", "Standing Lamp", "Arc Lamp"],
  // },
  // { category: "Head Board", alternate: ["Head Board", "HeadBoard"] },
  // { category: "King Mattress", alternate: ["King Mattress", "KingMattress"] },
  // {
  //   category: "Loveseat",
  //   alternate: [
  //     "Love Seat",
  //     "Loveseats",
  //     "Love Seat",
  //     "Love Seat Set",
  //     "LoveSeat Set",
  //   ],
  // },
  // {
  //   category: "Night Stand",
  //   alternate: ["Night Stand", "NightStand", "Drum"],
  // },
  // {
  //   category: "Queen Mattress",
  //   alternate: ["Queen Mattress", "QueenMattress"],
  // },
  // {
  //   category: "Shelving Unit",
  //   alternate: [
  //     "Shelving Unit",
  //     "ShelvingUnit",
  //     "Book Shelf",
  //     "Side Shelf",
  //     "Storage Cabinet",
  //     "Wall Mounted Shelf",
  //   ],
  // },
  // {
  //   category: "Side Table",
  //   alternate: ["Side Table", "SideTable", "End Table"],
  // },
  // {
  //   category: "Sofa",
  //   alternate: [
  //     "Sofa Set",
  //     "Sofa",
  //     "Living Room Set",
  //     "Sofa Sectionals",
  //     "Sofa Sectional",
  //   ],
  // },
  // {
  //   category: "Sofa Bed",
  //   alternate: ["Sofa Bed", "SofaBed", "Convertible Sofa"],
  // },
  // {
  //   category: "Table Lamp",
  //   alternate: ["Table Lamp", "TableLamp", "Desk Lamp"],
  // },
  // {
  //   category: "Throw Blanket",
  //   alternate: [
  //     "Throw Blanket",
  //     "ThrowBlanket",
  //     "Toss Blanket",
  //     "Blanket",
  //     "Decorative Blanket",
  //   ],
  // },
  // {
  //   category: "Throw Pillow",
  //   alternate: [
  //     "Throw Pillow",
  //     "ThrowPillow",
  //     "Throw cushion",
  //     "Toss Pillow",
  //     "Toss Cushion",
  //     "Decorative Pillow",
  //   ],
  // },
  // { category: "Twin Mattress", alternate: ["Twin Mattress", "TwinMattress"] },
  // {
  //   category: "Sconce",
  //   alternate: ["Sconce", "wall lamp", "Wall Lamps"],
  // },
  // {
  //   category: "Outdoor Furniture",
  //   alternate: [
  //     "Outdoor Furniture",
  //     "OutdoorFurniture",
  //     "Patio Furniture",
  //     "Exterior Furniture",
  //     "Outdoor",
  //     "patio",
  //   ],
  // },
];

const storeFulhausProductCategoryMap = (categories) => {
  productCategoryFulhausMap = categories;
};

export { storeFulhausProductCategoryMap, productCategoryFulhausMap };
