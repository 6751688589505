import React from "react";
import "./LandingPage.css";
import Logo from "../../Logo/fulhaus-logo.png";
import LandingPageImage from "../../Images/landing_page_image.jpg";
import MenuCard from "./MenuCard/MenuCard";
import Footer from "../Footer/Footer";
import menus from "../../Data/menus";
import openURL from "../../Functions/openURL";
import { connect } from "react-redux";

const UnconnectedLandingPage = (props) => {
  const goToHomePage = (event) => {
    // openURL("https://apps.fulhaus.com");
    openURL("http://localhost:3006");
  };

  const handleMenuCardClick = (link) => {
    props.history.push(link);
  };

  return (
    <React.Fragment>
      <div className="landing-page-wrapper">
        <div className="landing-page-body">
          <div
            className="landing-page-body-left-section"
            style={{ backgroundImage: "url(" + LandingPageImage + ")" }}
          >
            <div
              className="landing-page-logo"
              style={{ backgroundImage: "url(" + Logo + ")" }}
              onClick={(e) => goToHomePage(e)}
            ></div>
            <h1>Vendor Manager</h1>
            <p>Onboard vendors, manage vendor products and stats</p>
          </div>
          <div className="landing-page-body-right-section">
            <React.Fragment>
              {menus.map((menu, index) => {
                return (
                  <MenuCard
                    key={index}
                    menu={menu}
                    handleMenuCardClick={(l) => handleMenuCardClick(l)}
                  />
                );
              })}
            </React.Fragment>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const LandingPage = connect(mapStateToProps)(UnconnectedLandingPage);

export default LandingPage;
