import React, { useState, useEffect } from "react";
import "./ItemsPage.css";
import SideBar from "../SideBar/SideBar";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import Filters from "../Filters/Filters";
import fetchUtils from "../../Functions/fetchUtils";
import { BlockLoading } from "react-loadingg";
import statsFilterRegions from "../../Data/statsFilterRegions";
import ItemCard from "./ItemCard/ItemCard";
import copyOfArrayOrObject from "../../Functions/copyOfArrayOrObject";
import Button from "../CommonComponents/Button";

const ItemsPage = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [itemData, setItemData] = useState([]);
  const [itemRegion, setItemRegion] = useState("all");
  const [categories, setCategories] = useState([]);
  const [newAlternateNames, setNewAlternateNames] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageLimit, setCurrentPageLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [refreshingItems, setRefreshingItems] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    getAllCategories();
  }, []);

  const getAllCategories = async () => {
    setIsLoading(true);
    let response = await fetchUtils("/categories", "GET", "");
    if (response.success) {
      setCategories(response.data);
      getItemData("all", currentPage);
    } else {
      console.log(response.message);
      setIsLoading(false);
    }
  };

  const getItemData = async (region, page) => {
    setIsLoading(true);
    setItemRegion(region);
    let response = await fetchUtils(
      `/statistics/item-data/${region}?page=${page}&limit=${currentPageLimit}`,
      "GET",
      ""
    );
    if (response.success) {
      console.log(response.itemData);
      const newTotalPages = Math.ceil(
        response.itemData.totalNumberOfUnCategorizedItems / currentPageLimit
      );
      setTotalPages(newTotalPages);
      setItemData(response.itemData);
      setIsLoading(false);
    } else {
      console.log(response.message);
      setIsLoading(false);
    }
  };

  const handleRegionsFilter = (event) => {
    if (event.target.name === statsFilterRegions[itemRegion]) {
      return;
    }

    switch (event.target.name) {
      case "All":
        setCurrentPage(1);
        getItemData("all", 1);
        break;
      case "North America":
        setCurrentPage(1);
        getItemData("na", 1);
        break;
      case "Europe & UK":
        setCurrentPage(1);
        getItemData("eu", 1);
        break;
      default:
        setCurrentPage(1);
        getItemData("all", 1);
    }
  };

  const handleSelectedCategory = (event, id) => {
    const copyOfNewAlternateNames = copyOfArrayOrObject(newAlternateNames);
    if (!copyOfNewAlternateNames[id]) {
      copyOfNewAlternateNames[id] = {};
    }
    copyOfNewAlternateNames[id].category = event.target.name;
    setNewAlternateNames(copyOfNewAlternateNames);
  };

  const handleAlternateName = (event, id) => {
    const copyOfNewAlternateNames = copyOfArrayOrObject(newAlternateNames);
    if (!copyOfNewAlternateNames[id]) {
      copyOfNewAlternateNames[id] = {};
    }

    copyOfNewAlternateNames[id].alternate = event.target.value;
    setNewAlternateNames(copyOfNewAlternateNames);
  };

  const handlePageNavigation = (event, navigation) => {
    if (navigation === "previous") {
      setCurrentPage(currentPage - 1);
      getItemData(itemRegion, currentPage - 1);
    }

    if (navigation === "next") {
      setCurrentPage(currentPage + 1);
      getItemData(itemRegion, currentPage + 1);
    }
  };

  const handleRefreshCategories = async (event) => {
    if (Object.keys(newAlternateNames).length > 0) {
      setRefreshingItems(true);
      let response = await fetchUtils(
        "/categories/refresh",
        "POST",
        newAlternateNames
      );
      if (response.success) {
        setRefreshingItems(false);
        getItemData(itemRegion, currentPage);
      } else {
        console.log(response.message);
        setRefreshingItems(false);
      }
    }
  };

  return (
    <div className="items-page-wrapper">
      <div className="items-page-body-wrapper">
        <SideBar pageHistory={props.history} activeLink="/items" />
        <div className="items-page-body">
          <NavBar title="Items" />
          <div className="items-page-body-main">
            <div className="items-page-body-main-fixed">
              <div
                className="items-page-body-main-filters"
                style={{ visibility: isLoading ? "hidden" : "inherit" }}
              >
                <Filters handleRegionsFilter={(e) => handleRegionsFilter(e)} />
              </div>
              <div className="items-page-body-main-stats">
                {isLoading ? (
                  <div className="items-page-body-main-items-details-body items-page-loader">
                    <BlockLoading color="#FF4B30" />
                    <p>Loading Data ... </p>
                  </div>
                ) : (
                  <div className="items-page-body-main-items-details-body">
                    <div className="items-page-body-main-items-details-body-fixed">
                      <div className="items-page-body-main-items-refresh">
                        <Button
                          name="refresh"
                          background="#FF4E24"
                          borderRadius="calc(50px + 1vw)"
                          width="150px"
                          enabled={!refreshingItems}
                          height="calc(20px + 1vw)"
                          padding="calc(2px + 0.2vw)"
                          onClick={(e) => handleRefreshCategories(e)}
                          color="#ffffff"
                          label={refreshingItems ? "Refreshing..." : "Refresh"}
                          fontSize="calc(8px + 0.5vw)"
                        />
                      </div>
                      {itemData.unCategorizedItems.map((item, index) => {
                        return (
                          <ItemCard
                            key={index}
                            categories={categories.map(
                              (category) => category.category
                            )}
                            item={item}
                            handleSelectedCategory={(e) =>
                              handleSelectedCategory(e, item._id)
                            }
                            handleAlternateName={(e) =>
                              handleAlternateName(e, item._id)
                            }
                          />
                        );
                      })}

                      <div className="items-page-body-pagination-buttons">
                        <div style={{ width: "25%" }}>
                          {currentPage > 1 && (
                            <Button
                              name="previous"
                              background="transparent"
                              borderRadius="calc(50px + 1vw)"
                              width="100%"
                              enabled={true}
                              height="calc(20px + 1vw)"
                              padding="calc(2px + 0.2vw)"
                              onClick={(e) =>
                                handlePageNavigation(e, "previous")
                              }
                              color="#1f1f1f"
                              label="Previous"
                              fontSize="calc(5px + 0.5vw)"
                            />
                          )}
                        </div>
                        <p>
                          Page
                          <span> {currentPage} </span>
                          of
                          <span> {totalPages} </span>
                        </p>
                        <div style={{ width: "25%" }}>
                          {currentPage < totalPages && (
                            <Button
                              name="next"
                              background="transparent"
                              borderRadius="calc(50px + 1vw)"
                              width="100%"
                              enabled={true}
                              height="calc(20px + 1vw)"
                              padding="calc(2px + 0.2vw)"
                              onClick={(e) => handlePageNavigation(e, "next")}
                              color="#1f1f1f"
                              label="Next"
                              fontSize="calc(5px + 0.5vw)"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ItemsPage;
