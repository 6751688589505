import React, { useState, useEffect } from "react";
import "./VendorsPage.css";
import SideBar from "../SideBar/SideBar";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import Filters from "../Filters/Filters";
import { BlockLoading } from "react-loadingg";
import fetchUtils from "../../Functions/fetchUtils";
import VendorCard from "./VendorCard/VendorCard";
import statsFilterRegions from "../../Data/statsFilterRegions";

const VendorsPage = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [vendorData, setVendorData] = useState([]);
  const [vendorRegion, setVendorRegion] = useState("all");

  useEffect(() => {
    setIsLoading(true);

    getVendorData("all");
  }, []);

  const getVendorData = async (region) => {
    setIsLoading(true);
    setVendorRegion(region);
    let response = await fetchUtils(
      "/statistics/vendor-data/" + region,
      "GET",
      ""
    );
    if (response.success) {
      setVendorData(response.vendorData);
      setIsLoading(false);
    } else {
      console.log(response.message);
      setIsLoading(false);
    }
  };

  const handleRegionsFilter = (event) => {
    if (event.target.name === statsFilterRegions[vendorRegion]) {
      return;
    }

    switch (event.target.name) {
      case "All":
        getVendorData("all");
        break;
      case "North America":
        getVendorData("na");
        break;
      case "Europe & UK":
        getVendorData("eu");
        break;
      default:
        getVendorData("all");
    }
  };

  return (
    <div className="vendors-page-wrapper">
      <div className="vendors-page-body-wrapper">
        <SideBar pageHistory={props.history} activeLink="/vendors" />
        <div className="vendors-page-body">
          <NavBar title="vendors" />

          <div className="vendors-page-body-main">
            <div className="vendors-page-body-main-fixed">
              <div
                className="vendors-page-body-main-filters"
                style={{ visibility: isLoading ? "hidden" : "inherit" }}
              >
                <Filters handleRegionsFilter={(e) => handleRegionsFilter(e)} />
              </div>
              <div className="vendors-page-body-main-stats">
                <div className="vendors-page-body-main-vendors-details-header">
                  <div className="header-item-1"></div>
                  <h5 className="header-item-2">Region</h5>
                  <h5 className="header-item-3">Name</h5>
                  <h5 className="header-item-4">Total Items</h5>
                  <h5 className="header-item-5">Categorized Items</h5>
                  <h5 className="header-item-6">Uncategorized Items</h5>
                </div>
                {isLoading ? (
                  <div className="vendors-page-body-main-vendors-details-body vendors-page-loader">
                    <BlockLoading color="#FF4B30" />
                    <p>Loading Data ... </p>
                  </div>
                ) : (
                  <div className="vendors-page-body-main-vendors-details-body">
                    <div className="vendors-page-body-main-vendors-details-body-fixed">
                      {vendorData.map((vendor, index) => {
                        return <VendorCard key={index} vendor={vendor} />;
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default VendorsPage;
