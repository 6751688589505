import React, { useState, useEffect } from "react";
import "./OnboardingPage.css";
import UploadCSVXLFile from "./UploadCSVXLFile/UploadCSVXLFile";
import SideBar from "../SideBar/SideBar";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import fetchUtils from "../../Functions/fetchUtils";
import { BlockLoading } from "react-loadingg";
import { storeFulhausProductCategoryMap } from "../../Data/productCategoryFulhausMap";

const OnboardingPage = (props) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    const getFulhausCategoryMap = async () => {
      let response = await fetchUtils("/categories", "GET");
      if (response.success) {
        storeFulhausProductCategoryMap(response.data);
        setIsLoading(false);
      } else {
        console.log(response.message);
        setIsLoading(false);
      }
    };

    getFulhausCategoryMap();
  }, []);

  return (
    <div className="onboarding-page-wrapper">
      <div className="onboarding-page-body-wrapper">
        <SideBar pageHistory={props.history} activeLink="/onboarding" />
        <div className="onboarding-page-body">
          <NavBar title="Onboarding" />
          {isLoading ? (
            <div className="onboarding-page-body-main onboarding-page-loader">
              <BlockLoading color="#FF4B30" />
              <p>Loading ... </p>
            </div>
          ) : (
            <div className="onboarding-page-body-main">
              <div className="onboarding-page-body-main-fixed">
                <UploadCSVXLFile />
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OnboardingPage;
