import React, { useState, useEffect } from "react";
import "./SyncVendorDetailsPage.css";
import SideBar from "../SideBar/SideBar";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import fetchUtils from "../../Functions/fetchUtils";
import { BlockLoading } from "react-loadingg";
import { storeFulhausProductCategoryMap } from "../../Data/productCategoryFulhausMap";
import InputBox from "../CommonComponents/InputBox";
import { validateTextRequired } from "../../Functions/validateInputs";
import Button from "../CommonComponents/Button";
import DropDownList from "../CommonComponents/DropDownList";
import vendorRegions from "../../Data/vendorRegions";
import ChevronDown from "../../Icons/chevron_down.png";
import fetchUtil from "../../Functions/fetchUtils";

const SyncVendorDetailsPage = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [vendorID, setVendorID] = useState("");
  const [vendorRegion, setVendorRegion] = useState("");
  const [processingData, setProcessingData] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [syncMessage, setSyncMessage] = useState("Syncing vendor details to db..");

  useEffect(() => {
    setIsLoading(true);

    const getFulhausCategoryMap = async () => {
      let response = await fetchUtils("/categories", "GET");
      if (response.success) {
        storeFulhausProductCategoryMap(response.data);
        setIsLoading(false);
      } else {
        console.log(response.message);
        setIsLoading(false);
      }
    };

    getFulhausCategoryMap();
  }, []);


  const handleVendorID = (event) => {
    setErrorMessage(null);
    setVendorID(event.target.value);
  };

    const handleSelectedVendorRegion = (event) => {
    setErrorMessage(null);
    setVendorRegion(event.target.name);
  };


  const HandleDataMap = async (event) =>
  {
    setErrorMessage(null);
    setProcessingData(true);
    if (!vendorID || vendorID === "")
    {
      setErrorMessage("Vendor Airtable ID is required");
      setProcessingData(false);
      return;
    }
    if (!vendorRegion || vendorRegion === "")
    {
      setErrorMessage("Vendor Region is required");
      setProcessingData(false);
      return;
    }

    const response = await fetchUtil(
      "/airtable/vendors/" + vendorID + "/" + vendorRegion + "/mongo",
      "GET",
      ""
    );

    if (response.success)
    {
      setSyncMessage("Vendor details synced!")
      setTimeout(() =>
      {
        setVendorID("");
        setVendorRegion("");
        setSyncMessage("Syncing vendor details to db..");
        setProcessingData(false);
      }, 3000);
    } else
    {
      setErrorMessage(response.message);
      setProcessingData(false);
    }
  };
  

  return (
    <div className="onboarding-page-wrapper">
      <div className="onboarding-page-body-wrapper">
        <SideBar pageHistory={props.history} activeLink="/onboarding" />
        <div className="onboarding-page-body">
          <NavBar title="Onboarding" />
          {isLoading ? (
            <div className="onboarding-page-body-main onboarding-page-loader">
              <BlockLoading color="#FF4B30" />
              <p>Loading ... </p>
            </div>
          ) : (
            <div className="onboarding-page-body-main">
              <div className="onboarding-page-body-main-fixed">
            <div className="upload-csv-xl-file-details-header-row">
              <p>Enter Vendor ID in Airtable:</p>
              <InputBox
                name="Vendor ID"
                type="text"
                background="#F5F6FA"
                border="1px solid #DCE1EB"
                borderRadius="5px"
                width="250px"
                height="40px"
                padding="0 calc(2px + 1vw)"
                margin="0"
                fontSize="calc(14px + 0.1vw)"
                placeholder="Vendor Airtable ID"
                value={vendorID}
                validation={validateTextRequired(vendorID)}
                validationErrorMessage="Vendor Airtable ID is required"
                onChange={(e) => handleVendorID(e)}
              />
                  </div>
                              <div className="upload-csv-xl-file-details-header-row">
              <p>Vendor Region:</p>
              <DropDownList
                label="Select"
                labelFontSize="calc(14px + 0.2vw)"
                labelBackground="#F5F6FA"
                labelBorder="1px solid #DCE1EB"
                labelBorderRadius="5px"
                labelColor="#1f1f1f"
                labelAlign="space-between"
                labelHeight="40px"
                labelWidth="150px"
                labelPadding="0 calc(5px + 0.5vw)"
                listItems={vendorRegions}
                icon={ChevronDown}
                iconSize="10px"
                listAlign="left"
                listPadding=" 15px calc(8px + 1vw)"
                listMargin="0 0 calc(2px + 0.5vw) 0"
                listItemHoverBackground="#ff4b30"
                resetLabel={true}
                iconColorInvert={true}
                onSelect={(e) => handleSelectedVendorRegion(e)}
              />
            </div>
            <div className="upload-csv-xl-file-footer-bottom-wrapper">
            <p>{errorMessage}</p>
            <div className="upload-csv-xl-file-footer-bottom-button-row">
              <Button
                name="submit"
                background="#ff4b30"
                borderRadius="10px"
                width="250px"
                enabled={!processingData}
                height="40px"
                padding="calc(2px + 0.2vw)"
                onClick={(e) => HandleDataMap(e)}
                color="#ffffff"
                label={processingData ? syncMessage : "Submit"}
                fontSize="calc(5px + 0.8vw)"
              />
            </div>
          </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SyncVendorDetailsPage;
