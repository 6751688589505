import React, { useState, useEffect } from "react";
import "./DashboardPage.css";
import SideBar from "../SideBar/SideBar";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import { BlockLoading } from "react-loadingg";
import fetchUtils from "../../Functions/fetchUtils";
import { Pie } from "react-chartjs-2";

const DashboardPage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [overviewData, setOverviewData] = useState([]);
  const [pieData, setPieData] = useState({});

  useEffect(() => {
    setIsLoading(true);
    const getOverview = async () => {
      let response = await fetchUtils("/statistics/overview", "GET", "");
      if (response.success) {
        setOverviewData(response.overview);

        const newPieData = {
          labels: ["EU", "NA"],
          datasets: [
            {
              data: [response.overview[0].eu, response.overview[0].na],
              backgroundColor: ["#FF4E24", "#0070DA"],
              hoverBackgroundColor: ["#FF6384", "#36A2EB"],
            },
          ],
        };
        setPieData(newPieData);
        setIsLoading(false);
      } else {
        console.log(response.message);
        setIsLoading(false);
      }
    };

    getOverview();
  }, []);

  return (
    <div className="dashboard-page-wrapper">
      <div className="dashboard-page-body-wrapper">
        <SideBar pageHistory={props.history} activeLink="/dashboard" />
        <div className="dashboard-page-body">
          <NavBar title="Dashboard" />
          <div className="dashboard-page-body-main">
            <div className="dashboard-page-body-main-fixed">
              <div className="dashboard-page-body-main-stats">
                {isLoading ? (
                  <div className="dashboard-page-body-main-dashboard-details-body dashboard-page-loader">
                    <BlockLoading color="#FF4B30" />
                    <p>Loading Data ... </p>
                  </div>
                ) : (
                  <div className="dashboard-page-body-main-dashboard-details-body">
                    <div className="dashboard-page-body-main-dashboard-details-body-fixed">
                      <div className="dashboard-page-body-main-dashboard-row">
                        <div>
                          <h5>Vendors Summary</h5>
                          <Pie
                            data={pieData}
                            width={100}
                            // height={100}
                            options={{ maintainAspectRatio: false }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DashboardPage;
