import React, { useState, useEffect } from "react";
import "./UploadCSVXLFile.css";
import copyOfArrayOrObject from "../../../Functions/copyOfArrayOrObject";
import XLSX from "xlsx";
import DropDownList from "../../CommonComponents/DropDownList";
import ChevronDown from "../../../Icons/chevron_down.png";
import InputBox from "../../CommonComponents/InputBox";
import { validateTextRequired } from "../../../Functions/validateInputs";
import productFulhausFormat from "../../../Data/productFulhausFormat";
import Button from "../../CommonComponents/Button";
import PageModal from "../../CommonComponents/PageModal";
import UnmatchedFields from "./UnmatchedFields/UnmatchedFields";
import { productCategoryFulhausMap } from "../../../Data/productCategoryFulhausMap";
import fetchUtil from "../../../Functions/fetchUtils";
import Line from "../../CommonComponents/Line";
import Gap from "../../CommonComponents/Gap";
import vendorRegions from "../../../Data/vendorRegions";
import fetchUtils from "../../../Functions/fetchUtils";
import { FiXCircle } from "react-icons/fi";

const UploadCSVXLFile = (props) => {
  const [attachments, setAttachments] = useState(null);
  const [sheetNames, setSheetNames] = useState(null);
  const [workbook, setWorkbook] = useState(null);
  const [vendorID, setVendorID] = useState("");
  const [vendorRegion, setVendorRegion] = useState("");
  const [vendorRawData, setVendorRawData] = useState(null);
  const [vendorRawDataKeys, setVendorRawDataKeys] = useState(null);
  const [vendorRawDataMappedKeys, setVendorRawDataMappedKeys] = useState({});
  const [productFulhausFormatKeys, setProductFulhausFormatKeys] = useState([]);
  const [
    unselectedProductFulhausFormatKeys,
    setUnselectedProductFulhausFormatKeys,
  ] = useState([]);
  const [openUnselectedDataDisplay, setOpenUnselectedDataDisplay] = useState(
    false
  );
  const [errorMessage, setErrorMessage] = useState(null);
  const [
    activeProductCategoryFulhausMap,
    setActiveProductCategoryFulhausMap,
  ] = useState([]);
  const [processingData, setProcessingData] = useState(false);
  const [randomExcelPreviewRows, setRandomExcelPreviewRows] = useState([]);

  useEffect(() => {
    setProductFulhausFormatKeys(productFulhausFormat.map((data) => data.key));
    const copyOfProductCategoryFulhausMap = copyOfArrayOrObject(
      productCategoryFulhausMap
    );
    setActiveProductCategoryFulhausMap(copyOfProductCategoryFulhausMap);
  }, []);

  const handleFile = (event) => {
    setErrorMessage(null);
    setAttachments(event.target.files);
    const files = event.target.files,
      f = files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      setWorkbook(workbook);
      setSheetNames(workbook.SheetNames);
    };
    reader.readAsArrayBuffer(f);
  };

  const handleSelectedSheet = (event) => {
    setErrorMessage(null);
    const workBookSheetToJSON = XLSX.utils.sheet_to_json(
      workbook.Sheets[event.target.name],
      { defval: "" }
    );

    setVendorRawDataKeys(Object.keys(workBookSheetToJSON[0]));
    setVendorRawData(workBookSheetToJSON);

    const newRandomExcelPreviewRows = [];
    for (let i = 0; i < 3; i++) {
      newRandomExcelPreviewRows.push(
        randomNumber(0, workBookSheetToJSON.length)
      );
      if (i === 2) {
        setRandomExcelPreviewRows(newRandomExcelPreviewRows);
      }
    }
  };

  const handleVendorID = (event) => {
    setErrorMessage(null);
    setVendorID(event.target.value);
  };

  const handleSelectedVendorRegion = (event) => {
    setErrorMessage(null);
    setVendorRegion(event.target.name);
  };

  const randomNumber = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const getVendorRawDataPreview = (key) => {
    let vendorRawDataPreviewItems = [];

    for (let i = 0; i < randomExcelPreviewRows.length; i++) {
      if (vendorRawData[randomExcelPreviewRows[i]]) {
        vendorRawDataPreviewItems.push(
          vendorRawData[randomExcelPreviewRows[i]][key]
        );
      }
    }

    return vendorRawDataPreviewItems;
  };

  const handleSelectedFulhausEquivalentKey = (event, vendorRawDataKey) => {
    setErrorMessage(null);
    let _vendorRawDataKey = vendorRawDataKey;

    const copyOfVendorRawDataMappedKeys = copyOfArrayOrObject(
      vendorRawDataMappedKeys
    );

    if (event.target.name === "imageURLs") {
      let imageURLKeys = [];
      if (copyOfVendorRawDataMappedKeys.imageURLs) {
        imageURLKeys = copyOfArrayOrObject(
          copyOfVendorRawDataMappedKeys.imageURLs
        );
      }
      imageURLKeys.push(vendorRawDataKey);
      _vendorRawDataKey = imageURLKeys;
    }

    copyOfVendorRawDataMappedKeys[event.target.name] = _vendorRawDataKey;
    setVendorRawDataMappedKeys(copyOfVendorRawDataMappedKeys);

    const copyOfProductFulhausFormatKeys = copyOfArrayOrObject(
      productFulhausFormatKeys
    );

    const productFulhausFormatKeysWithselectedCategoryRemoved = copyOfProductFulhausFormatKeys.filter(
      (key) => {
        return key !== event.target.name || event.target.name === "imageURLs";
      }
    );

    setProductFulhausFormatKeys(
      productFulhausFormatKeysWithselectedCategoryRemoved
    );
  };

  const handleRemoveFulhausEquivalentKey = (event, fulhausEquivalentKey) => {
    const copyOfVendorRawDataMappedKeys = copyOfArrayOrObject(
      vendorRawDataMappedKeys
    );

    delete copyOfVendorRawDataMappedKeys[fulhausEquivalentKey];

    const copyOfProductFulhausFormatKeys = copyOfArrayOrObject(
      productFulhausFormatKeys
    );

    copyOfProductFulhausFormatKeys.unshift(fulhausEquivalentKey);

    setVendorRawDataMappedKeys(copyOfVendorRawDataMappedKeys);
    setProductFulhausFormatKeys(copyOfProductFulhausFormatKeys);
  };

  const getSelectedCategory = (rawDataKey, index) => {
    const copyOfVendorRawDataMappedKeys = copyOfArrayOrObject(
      vendorRawDataMappedKeys
    );

    let selectedCategory = null;

    for (const [key, value] of Object.entries(copyOfVendorRawDataMappedKeys)) {
      if (rawDataKey === value) {
        selectedCategory = key;
      }
      if (value) {
        if (value.includes(rawDataKey)) {
          selectedCategory = key;
        }
      }
    }

    return selectedCategory;
  };

  const handleSelectedFulhausAlternativeKey = (event, data) => {
    setErrorMessage(null);
    const specialSelection = [
      "Assign 1st image URL",
      "Assign Item Name",
      "Compute as trade price with a markup of 1.5",
      "Ignore",
    ];
    const copyOfVendorRawDataMappedKeys = copyOfArrayOrObject(
      vendorRawDataMappedKeys
    );

    if (specialSelection.includes(event.target.name)) {
      if (
        event.target.name === "Assign 1st image URL" &&
        copyOfVendorRawDataMappedKeys.imageURLs
      ) {
        copyOfVendorRawDataMappedKeys[data.key] =
          copyOfVendorRawDataMappedKeys.imageURLs[0];
      }
      if (
        event.target.name === "Assign Item Name" &&
        copyOfVendorRawDataMappedKeys.itemName
      ) {
        copyOfVendorRawDataMappedKeys[data.key] =
          copyOfVendorRawDataMappedKeys.itemName;
      }

      if (
        event.target.name === "Compute as trade price with a markup of 1.5" &&
        copyOfVendorRawDataMappedKeys.tradePrice
      ) {
        copyOfVendorRawDataMappedKeys[data.key] =
          copyOfVendorRawDataMappedKeys.tradePrice;
      }

      if (event.target.name === "Ignore") {
        copyOfVendorRawDataMappedKeys[data.key] = "";
      }
    } else {
      copyOfVendorRawDataMappedKeys[data.key] = event.target.name;
    }

    setVendorRawDataMappedKeys(copyOfVendorRawDataMappedKeys);
  };

  const handleUnselectedDataDisplay = (event) => {
    setOpenUnselectedDataDisplay(false);
  };

  const HandleDataMap = async (event) => {
    setErrorMessage(null);
    setOpenUnselectedDataDisplay(false);
    setProcessingData(true);
    if (
      Object.keys(vendorRawDataMappedKeys).length < productFulhausFormat.length
    ) {
      let unselectedKeys = [];

      const allFulhausKeys = productFulhausFormat.map((data) => data.key);
      const allMappedKeys = Object.keys(vendorRawDataMappedKeys);

      const copyOfProductFulhausFormat = copyOfArrayOrObject(
        productFulhausFormat
      );

      allFulhausKeys.forEach((key, index) => {
        if (!allMappedKeys.includes(key)) {
          if (key === "MAP" && !allMappedKeys.includes("tradePrice")) {
            copyOfProductFulhausFormat[index].required = true;
          }

          if (key === "MSRP" && !allMappedKeys.includes("tradePrice")) {
            copyOfProductFulhausFormat[index].required = true;
          }

          unselectedKeys.push(copyOfProductFulhausFormat[index]);
        }
      });

      setUnselectedProductFulhausFormatKeys(unselectedKeys);
      setOpenUnselectedDataDisplay(true);
      setProcessingData(false);
    } else {
      setOpenUnselectedDataDisplay(false);
      if (!vendorID || vendorID === "") {
        setErrorMessage("Vendor Airtable ID is required");
        setProcessingData(false);
        return;
      }
      if (!vendorRegion || vendorRegion === "") {
        setErrorMessage("Vendor Region is required");
        setProcessingData(false);
        return;
      }

      const response = await fetchUtil(
        "/airtable/vendors/" + vendorID + "/" + vendorRegion + "/mongo",
        "GET",
        ""
      );

      if (response.success) {
        handleFulhausCategoryMap(response.data);
      } else {
        setErrorMessage(response.message);
        setProcessingData(false);
      }
    }
  };

  const handleFulhausCategoryMap = (vendorDetails) => {
    setErrorMessage(null);
    setProcessingData(true);
    const newFinalizedProductData = [];
    const copyOfVendorRawData = copyOfArrayOrObject(vendorRawData);
    for (let i = 0; i < copyOfVendorRawData.length; i++) {
      let mappedData = copyOfArrayOrObject(vendorRawDataMappedKeys);
      productFulhausFormat.forEach((data) => {
        if (vendorRawData[i][vendorRawDataMappedKeys[data.key]]) {
          mappedData[data.key] =
            vendorRawData[i][vendorRawDataMappedKeys[data.key]];
        }
      });

      const copyOfMappedData = copyOfArrayOrObject(mappedData);

      mappedData.vendorName = vendorDetails.name;
      mappedData.vendorID = vendorDetails._id;
      mappedData.vendorAirtableID = vendorDetails.airtableID;
      mappedData.vendorRegion = vendorDetails.region;
      mappedData.vendorProductName = copyOfMappedData.itemName;
      mappedData.stockQty = 0;
      mappedData.stockDate = null;
      mappedData.restockDate = null;
      mappedData.modifiedOn = Date.now;
      mappedData.createdOn = Date.now;

      if (typeof mappedData.weight === "string") {
        mappedData.weight = parseFloat(copyOfMappedData.weight);
        if (isNaN(mappedData.weight)) {
          mappedData.weight = null;
        }
      }

      if (typeof mappedData.height === "string") {
        mappedData.height = parseFloat(copyOfMappedData.height);
        if (isNaN(mappedData.height)) {
          mappedData.height = 0;
        }
      }

      if (typeof mappedData.length === "string") {
        mappedData.length = parseFloat(copyOfMappedData.length);
        if (isNaN(mappedData.length)) {
          mappedData.length = 0;
        }
      }

      if (typeof mappedData.width === "string") {
        mappedData.width = parseFloat(copyOfMappedData.width);
        if (isNaN(mappedData.width)) {
          mappedData.width = 0;
        }
      }

      if (!mappedData.dimension || mappedData.dimension === " Ignore") {
        mappedData.dimension =
          copyOfMappedData.length +
          "L " +
          copyOfMappedData.width +
          "W " +
          copyOfMappedData.height +
          "H";
      }

      if (typeof mappedData.casePackQty === "string") {
        mappedData.casePackQty = parseInt(copyOfMappedData.casePackQty);
        if (isNaN(mappedData.casePackQty)) {
          mappedData.casePackQty = 1;
        }
      }

      if (mappedData.MAP === mappedData.tradePrice) {
        mappedData.MAP = copyOfMappedData.tradePrice * 1.5;
      }

      if (mappedData.MSRP === mappedData.tradePrice) {
        mappedData.MSRP = copyOfMappedData.tradePrice * 1.5;
      }

      if (typeof mappedData.MAP === "string") {
        mappedData.MAP = parseFloat(copyOfMappedData.MAP);
      }

      if (typeof mappedData.MSRP === "string") {
        mappedData.MSRP = parseFloat(copyOfMappedData.MSRP);
      }

      if (
        (isNaN(mappedData.MAP) || !mappedData.MAP || mappedData.MAP === 0) &&
        mappedData.tradePrice
      ) {
        mappedData.MAP = copyOfMappedData.tradePrice * 1.5;
      }

      if (
        (isNaN(mappedData.MSRP) || !mappedData.MSRP || mappedData.MSRP === 0) &&
        mappedData.tradePrice
      ) {
        mappedData.MSRP = copyOfMappedData.tradePrice * 1.5;
      }

      const checkIfURLIsImageURL = (url) => {
        if (typeof url === "string")
        {
          let isImageURL = null;
          const pattern = ".jpg|.gif|.png|.JPG|.GIF|.PNG|.JPEG|.jpeg";

          pattern.split("|").forEach((imageType) =>
          {
            if (url.includes(imageType))
            {
              if (!isImageURL)
              {
                isImageURL = true;
              }
              
            }
          })

          return isImageURL
        }

        return null;
      };

      if (typeof mappedData.imageURLs === "string") {
        let imagesArray = [];

        imagesArray.push(mappedData.imageURLs);

        copyOfMappedData.imageURLs = imagesArray;
      }

      let imgURLs = [];
      copyOfMappedData.imageURLs.forEach((key) => {
        if (checkIfURLIsImageURL(vendorRawData[i][key])) {
          if (copyOfMappedData.itemLink === key) {
            mappedData.itemLink = vendorRawData[i][key];
          }
          if (vendorRawData[i][key]) {
            imgURLs.push(vendorRawData[i][key]);
          }
        }
      });

      mappedData.imageURLs = imgURLs;

      const copyOfActiveProductCategoryFulhausMap = copyOfArrayOrObject(
        activeProductCategoryFulhausMap
      );

      if (!mappedData.fulhausCategory) {
        copyOfActiveProductCategoryFulhausMap.forEach((mapData, index) =>
        {
          if (mapData)
          {
                      const regex = new RegExp(
            "\\b" + mapData.category.toLowerCase() + "\\b"
          );
          if (
            copyOfMappedData["itemName"] &&
            copyOfMappedData["itemName"].toLowerCase().match(regex)
          ) {
            mappedData.fulhausCategory = mapData.category;
          }
          }

        });
      }

      if (!mappedData.fulhausCategory) {
        copyOfActiveProductCategoryFulhausMap.forEach((mapData, index) =>
        {
          mapData.alternate.forEach((data) =>
          {
            if (data)
            {
                         const regex = new RegExp("\\b" + data.toLowerCase() + "\\b");
            if (
              copyOfMappedData["itemName"] &&
              copyOfMappedData["itemName"].toLowerCase().match(regex)
            ) {
              mappedData.fulhausCategory = mapData.category;
            } 
            }

          });
        });
      }

      if (!mappedData.fulhausCategory) {
        copyOfActiveProductCategoryFulhausMap.forEach((mapData, index) => {
          mapData.alternate.forEach((data) =>
          {
            if (data)
            {
                         const regex = new RegExp("\\b" + data.toLowerCase() + "\\b");
            if (
              copyOfMappedData["vendorProductCategory"] &&
              copyOfMappedData["vendorProductCategory"]
                .toLowerCase()
                .match(regex)
            ) {
              mappedData.fulhausCategory = mapData.category;
            } 
            }

          });
        });
      }

      if (!mappedData.fulhausCategory) {
        mappedData.fulhausCategory = undefined;
      }

      const copyOfMappedData2 = copyOfArrayOrObject(mappedData);

      mappedData.tradePrice =
        copyOfMappedData2.tradePrice / copyOfMappedData2.casePackQty;
      mappedData.MAP = copyOfMappedData2.MAP / copyOfMappedData2.casePackQty;
      mappedData.MSRP = copyOfMappedData2.MSRP / copyOfMappedData2.casePackQty;

      newFinalizedProductData.push(mappedData);

      if (i === copyOfVendorRawData.length - 1)
      {
        console.log("here")
        handleUploadProducts(newFinalizedProductData);
      }
    }
  };

  const handleUploadProducts = async (finalizedData) => {
    setProcessingData(true);
    const copyOfFinalizedData = copyOfArrayOrObject(finalizedData);
    const finalizedDataFiltered = copyOfFinalizedData.filter((item) => {
      return (
        item.tradePrice &&
        !isNaN(item.tradePrice) &&
        typeof item.tradePrice !== "string" &&
        item.MSRP &&
        !isNaN(item.MSRP) &&
        item.MAP &&
        !isNaN(item.MAP) &&
        typeof item.MSRP !== "string" &&
        typeof item.MAP !== "string" &&
        (item.weight || item.weight === 0) &&
        !isNaN(item.weight) &&
        (item.height || item.height === 0) &&
        !isNaN(item.height) &&
        (item.width || item.width === 0) &&
        !isNaN(item.width) &&
        (item.length || item.length === 0) &&
        !isNaN(item.length) &&
        item.imageURLs.length > 0
      );
    });

    let response = await fetchUtils("/items", "POST", finalizedDataFiltered);
    if (response.success)
    {
            setProcessingData(false);
      console.log(response.message);
      alert(response.message);

    } else {
      setErrorMessage(response.message);
      setProcessingData(false);
    }
  };

  return (
    <div className="upload-csv-xl-wrapper">
      <div className="upload-csv-xl-box">
        <div>{attachments ? attachments[0].name : "No File Uploaded"}</div>
        <div className="upload-csv-xl-upload-btn-wrapper">
          <button>Upload Vendor Data</button>
          <input type="file" accept=".xls,.xlsx,.csv" onChange={handleFile} />
        </div>
      </div>
      {attachments && !sheetNames ? (
        <div>
          <h5 style={{ color: "#ff4b30", fontSize: "calc(10px + 0.5vw)" }}>
            {"Uploading data ..."}
          </h5>
        </div>
      ) : (
        attachments &&
        sheetNames && (
          <div className="upload-csv-xl-file-details-header">
            <div className="upload-csv-xl-file-details-header-row">
              <p>FileName:</p>
              <h4>{attachments[0].name}</h4>
            </div>
            <div className="upload-csv-xl-file-details-header-row">
              <p>Active Sheet:</p>
              <DropDownList
                label="Select"
                labelFontSize="calc(14px + 0.2vw)"
                labelBackground="#F5F6FA"
                labelBorder="1px solid #DCE1EB"
                labelBorderRadius="5px"
                labelColor="#1f1f1f"
                labelAlign="space-between"
                labelHeight="40px"
                labelWidth="250px"
                labelPadding="0 calc(5px + 0.5vw)"
                listItems={sheetNames}
                icon={ChevronDown}
                iconSize="10px"
                listAlign="left"
                listPadding=" 15px calc(8px + 1vw)"
                listMargin="0 0 calc(2px + 0.5vw) 0"
                listItemHoverBackground="#ff4b30"
                resetLabel={true}
                iconColorInvert={true}
                onSelect={(e) => handleSelectedSheet(e)}
              />
            </div>
            <Gap value="20px" />
            <Line width="100%" height="1px" color="#b8c5d3" />
            <Gap value="20px" />
            <div className="upload-csv-xl-file-details-header-row">
              <p>Vendor ID in Airtable:</p>
              <InputBox
                name="Vendor ID"
                type="text"
                background="#F5F6FA"
                border="1px solid #DCE1EB"
                borderRadius="5px"
                width="250px"
                height="40px"
                padding="0 calc(2px + 1vw)"
                margin="0"
                fontSize="calc(14px + 0.1vw)"
                placeholder="Vendor Airtable ID"
                value={vendorID}
                validation={validateTextRequired(vendorID)}
                validationErrorMessage="Vendor Airtable ID is required"
                onChange={(e) => handleVendorID(e)}
              />
            </div>
            <div className="upload-csv-xl-file-details-header-row">
              <p>Vendor Region:</p>
              <DropDownList
                label="Select"
                labelFontSize="calc(14px + 0.2vw)"
                labelBackground="#F5F6FA"
                labelBorder="1px solid #DCE1EB"
                labelBorderRadius="5px"
                labelColor="#1f1f1f"
                labelAlign="space-between"
                labelHeight="40px"
                labelWidth="150px"
                labelPadding="0 calc(5px + 0.5vw)"
                listItems={vendorRegions}
                icon={ChevronDown}
                iconSize="10px"
                listAlign="left"
                listPadding=" 15px calc(8px + 1vw)"
                listMargin="0 0 calc(2px + 0.5vw) 0"
                listItemHoverBackground="#ff4b30"
                resetLabel={true}
                iconColorInvert={true}
                onSelect={(e) => handleSelectedVendorRegion(e)}
              />
            </div>
          </div>
        )
      )}
      {vendorRawData && (
        <div className="upload-csv-xl-file-details-body">
          <div className="upload-csv-xl-file-details-body-row sticky">
            <div className="xl-row-item1 xl-row-header">
              <p>Vendor Data Fields</p>
            </div>
            <div className="xl-row-item2 xl-row-header">
              <p>Fulhaus Equivalent Fields</p>
            </div>
          </div>
          {vendorRawDataKeys.map((key, index) => {
            return (
              <div key={index} className="upload-csv-xl-file-details-body-row">
                <div className="xl-row-item1">
                  <h5>{key}</h5>
                  <small>Sample</small>
                  {getVendorRawDataPreview(key).map((item, itemIndex) => {
                    return (
                      <div
                        key={itemIndex}
                        className="upload-csv-xl-file-details-item-details"
                      >
                        <p>{item}</p>
                      </div>
                    );
                  })}
                </div>
                <div className="xl-row-item2">
                  {getSelectedCategory(key, index) ? (
                    <div className="upload-csv-xl-file-details-selected-category">
                      <p>{getSelectedCategory(key, index)}</p>
                      <FiXCircle
                        className="upload-csv-xl-file-details-selected-category-cancel-icon"
                        onClick={(e) =>
                          handleRemoveFulhausEquivalentKey(
                            e,
                            getSelectedCategory(key, index)
                          )
                        }
                      />
                    </div>
                  ) : (
                    <DropDownList
                      label="Select Equivalent"
                      labelFontSize="calc(14px + 0.2vw)"
                      labelBackground="#F5F6FA"
                      labelBorder="1px solid #DCE1EB"
                      labelBorderRadius="5px"
                      labelColor="#1f1f1f"
                      labelAlign="space-between"
                      labelHeight="40px"
                      labelWidth="calc(50px + 20vw)"
                      labelPadding="0 calc(5px + 0.5vw)"
                      listItems={productFulhausFormatKeys}
                      icon={ChevronDown}
                      iconSize="10px"
                      listAlign="left"
                      listPadding=" 15px calc(8px + 1vw)"
                      listMargin="0 0 calc(2px + 0.5vw) 0"
                      listItemHoverBackground="#ff4b30"
                      resetLabel={true}
                      searchable={true}
                      resetListItemsOnChange={true}
                      iconColorInvert={true}
                      onSelect={(e) =>
                        handleSelectedFulhausEquivalentKey(e, key)
                      }
                    />
                  )}
                </div>
              </div>
            );
          })}

          <div className="upload-csv-xl-file-footer-bottom-wrapper">
            <p>{errorMessage}</p>
            <div className="upload-csv-xl-file-footer-bottom-button-row">
              <Button
                name="submit"
                background="#ff4b30"
                borderRadius="10px"
                width="250px"
                enabled={!processingData}
                height="40px"
                padding="calc(2px + 0.2vw)"
                onClick={(e) => HandleDataMap(e)}
                color="#ffffff"
                label={processingData ? "Processing Data" : "Done"}
                fontSize="calc(5px + 0.8vw)"
              />
            </div>
          </div>
        </div>
      )}
      <PageModal
        visible={openUnselectedDataDisplay}
        component={
          <UnmatchedFields
            unselectedProductFulhausFormatKeys={
              unselectedProductFulhausFormatKeys
            }
            processingData={processingData}
            handleSelectedFulhausAlternativeKey={(e, d) =>
              handleSelectedFulhausAlternativeKey(e, d)
            }
            handleSubmitData={(e, d) => handleUnselectedDataDisplay(e)}
          />
        }
        onClose={(e) => handleUnselectedDataDisplay(e)}
      />
    </div>
  );
};

export default UploadCSVXLFile;
