import React from "react";
import "./UnmatchedFields.css";
import DropDownList from "../../../CommonComponents/DropDownList";
import Button from "../../../CommonComponents/Button";
import ChevronDown from "../../../../Icons/chevron_down.png";

const UnmatchedFields = (props) => {
  return (
    <div className="unmatched-fields-wrapper">
      <h1>Resolve Unmatched Fields</h1>
      <div className="unmatched-fields-details">
        {props.unselectedProductFulhausFormatKeys.map((data, index) => {
          return (
            <div key={index} className="unmatched-fields-details-row">
              <h5>{data.key}</h5>
              <div>
                {data.required ? (
                  <p>This field is required</p>
                ) : (
                  <DropDownList
                    label="Select Alternative"
                    labelFontSize="calc(14px + 0.2vw)"
                    labelBackground="#F5F6FA"
                    labelBorder="1px solid #DCE1EB"
                    labelBorderRadius="5px"
                    labelColor="#1f1f1f"
                    labelAlign="space-between"
                    labelHeight="40px"
                    labelWidth="calc(50px + 20vw)"
                    labelPadding="0 calc(5px + 0.5vw)"
                    listItems={data.alt}
                    icon={ChevronDown}
                    iconSize="10px"
                    listAlign="left"
                    listPadding=" 15px calc(8px + 1vw)"
                    listMargin="0 0 calc(2px + 0.5vw) 0"
                    listItemHoverBackground="#ff4b30"
                    resetLabel={true}
                    iconColorInvert={true}
                    onSelect={(e) =>
                      props.handleSelectedFulhausAlternativeKey(e, data)
                    }
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>

      <div className="unmatched-fields-details-button">
        <Button
          name="submit"
          background="#ff4b30"
          borderRadius="10px"
          width="250px"
          enabled={!props.processingData}
          height="40px"
          padding="calc(2px + 0.2vw)"
          onClick={(e) => props.handleSubmitData(e)}
          color="#ffffff"
          label={props.processingData ? "Processing Data" : "Done"}
          fontSize="calc(5px + 0.8vw)"
        />
      </div>
    </div>
  );
};

export default UnmatchedFields;
