import React, { useState } from "react";
import "./Filters.css";
import DownArrowIcon from "../../Icons/chevron_down.png";
import DropDownList from "../CommonComponents/DropDownList";
import DateFilterInput from "./DateFilterInput/DateFilterInput";
import { FiSearch } from "react-icons/fi";

const Filters = (props) => {
  const [filterDateRange, setFilterDateRange] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState("all");

  return (
    <div className="filters-wrapper">
      <div className="filters-regions-filter">
        <p>By Region</p>
        <DropDownList
          label="All"
          labelFontSize="calc(8px + 0.5vw)"
          labelBackground="#ffffff"
          labelBorder="1px solid #B8C5D3"
          labelBorderRadius="20px"
          labelColor="#748AA1"
          labelAlign="space-between"
          labelHeight="40px"
          labelPadding="0 calc(10px + 1vw)"
          listItems={["All", "North America", "Europe & UK"]}
          icon={DownArrowIcon}
          iconSize="10px"
          iconColorInvert={true}
          listAlign="left"
          listMargin="0 0 calc(2px + 0.5vw) 0"
          resetLabel={true}
          onSelect={(e) => props.handleRegionsFilter(e)}
        />
      </div>
      <div className="filters-date-filter">
        <p>By Date Range</p>
        <DateFilterInput filterDateRange={filterDateRange} />
      </div>
      <div className="filters-date-search">
        <FiSearch className="filters-date-search-button" />
      </div>
    </div>
  );
};

export default Filters;
