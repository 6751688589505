import React, { useState } from "react";

let Button = (props) => {
  const [clickEffect, setClickEffect] = useState(false);
  let style = {
    main: {
      textAlign: props.textAlign ? props.textAlign : "center",
      background: props.background ? props.background : "transparent",
      width: props.width,
      height: props.height,
      color: props.color,
      fontSize: props.fontSize ? props.fontSize : "18px",
      fontWeight: props.fontWeight ? props.fontWeight : "bold",
      border: props.border ? props.border : "none",
      borderRadius: props.borderRadius ? props.borderRadius : "50px",
      margin: props.margin ? props.margin : "10px 0",
      padding: props.padding ? props.padding : "10px 50px",
      justifyContent: props.justifyContent ? props.justifyContent : "center",
      opacity: (!props.enabled || clickEffect) && 0.5,
      cursor: !props.enabled && "not-allowed",
    },

    suffixIcon: {
      objectFit: props.suffixIconObjectFit
        ? props.suffixIconObjectFit
        : "cover",
      borderRadius: props.suffixIconBorderRadius
        ? props.suffixIconBorderRadius
        : "0",
    },

    prefixIcon: {
      objectFit: props.prefixIconObjectFit
        ? props.suffixIconObjectFit
        : "cover",
      borderRadius: props.prefixIconBorderRadius
        ? props.suffixIconBorderRadius
        : "0",
    },
  };

  const buttonClicked = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setClickEffect(true);
    props.enabled && props.onClick && props.onClick(event);
    setTimeout(() => {
      setClickEffect(false);
    }, 100);
  };

  return (
    <button name={props.name} style={style.main} onClick={buttonClicked}>
      <img
        style={style.prefixIcon}
        width={props.prefixIconWidth}
        height={
          props.prefixIconHeight
            ? props.prefixIconHeight
            : props.prefixIconWidth
        }
        src={props.prefixIcon}
        alt=""
      />
      {props.label}
      <img
        style={style.suffixIcon}
        width={props.suffixIconWidth}
        height={
          props.suffixIconHeight
            ? props.suffixIconHeight
            : props.suffixIconWidth
        }
        src={props.suffixIcon}
        alt=""
      />
    </button>
  );
};

export default Button;
