import React, { useEffect, useState } from "react";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import PageNotFound from "./Components/PageNotFound/PageNotFound";
import fetchUtils from "./Functions/fetchUtils";
import { BlockLoading } from "react-loadingg";
import openURL from "./Functions/openURL";
import LandingPage from "./Components/LandingPage/LandingPage";
import DashboardPage from "./Components/DashboardPage/DashboardPage";
import OverviewPage from "./Components/OverviewPage/OverviewPage";
import OnboardingPage from "./Components/OnboardingPage/OnboardingPage";
import VendorsPage from "./Components/VendorsPage/VendorsPage";
import ItemsPage from "./Components/ItemsPage/ItemsPage";
import CategoriesPage from "./Components/CategoriesPage/CategoriesPage";
import { connect } from "react-redux";
import SyncVendorDetailsPage from "./Components/SyncVendorDetailsPage/SyncVendorDetailsPage";

const UnconnectedApp = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    // if (!props.user) {
    //   const checkUserLoginStatus = async () => {
    //     let response = await fetchUtils("/users/status", "GET", "");

    //     if (response.success) {
    //       props.dispatch({
    //         type: "set-user",
    //         value: response.data,
    //       });
    //       setIsAuthenticated(true);
    //       setIsLoading(false);
    //     } else {
    //       console.log(response.message);
    //       if (process.env.REACT_APP_ENV === "development") {
    //         openURL("http://localhost:3006");
    //       } else {
    //         openURL("https://apps.fulhaus.com");
    //       }
    //     }
    //   };

    //   checkUserLoginStatus();
    //   return;
    // }
  }, []);

  const authenticatedRoute = (page) => {
    // if (!isAuthenticated) {
    //   if (process.env.REACT_APP_ENV === "development") {
    //     openURL("http://localhost:3006");
    //   } else {
    //     openURL("https://apps.fulhaus.com");
    //   }
    // }

    return page;
  };

  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact={true}
          path="/"
          component={authenticatedRoute(LandingPage)}
        />
        <Route
          exact={true}
          path="/dashboard"
          component={authenticatedRoute(DashboardPage)}
        />
        <Route
          exact={true}
          path="/overview"
          component={authenticatedRoute(OverviewPage)}
        />
        <Route
          exact={true}
          path="/onboarding"
          component={authenticatedRoute(OnboardingPage)}
        />
        <Route
          exact={true}
          path="/vendors"
          component={authenticatedRoute(VendorsPage)}
        />
        <Route
          exact={true}
          path="/items"
          component={authenticatedRoute(ItemsPage)}
        />
        <Route
          exact={true}
          path="/categories"
          component={authenticatedRoute(CategoriesPage)}
        />
        <Route
          exact={true}
          path="/sync-vendor-details"
          component={authenticatedRoute(SyncVendorDetailsPage)}
        />

        <Route exact={true} path="/404" component={PageNotFound} />
        <Redirect to="/404" />
      </Switch>
    </BrowserRouter>
  );
};

let App = connect()(UnconnectedApp);
export default App;
