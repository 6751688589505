import React, { useState, useEffect } from "react";
import "./OverviewPage.css";
import SideBar from "../SideBar/SideBar";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import TotalsCard from "./TotalsCard/TotalsCard";
import { BlockLoading } from "react-loadingg";
import fetchUtils from "../../Functions/fetchUtils";
import vendorStatsRegions from "../../Data/statsFilterRegions";
import Filters from "../Filters/Filters";
import overviewLinks from "../../Data/overviewLinks";

const OverviewPage = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [overviewData, setOverviewData] = useState([]);
  const [overviewRegion, setOverviewRegion] = useState("all");

  useEffect(() => {
    setIsLoading(true);
    const getOverview = async () => {
      let response = await fetchUtils("/statistics/overview", "GET", "");
      if (response.success) {
        setOverviewData(response.overview);
        setIsLoading(false);
      } else {
        console.log(response.message);
        setIsLoading(false);
      }
    };

    getOverview();
  }, []);

  const handleRegionsFilter = (event) => {
    switch (event.target.name) {
      case "All":
        setOverviewRegion("all");
        break;
      case "North America":
        setOverviewRegion("na");
        break;
      case "Europe & UK":
        setOverviewRegion("eu");
        break;
      default:
        setOverviewRegion("all");
    }
  };

  const getPercentage = (percentageParams, regionID, regionTotal) => {
    let percentage = 0;

    overviewData.forEach((data) => {
      if (data.title === percentageParams) {
        percentage = (regionTotal / data[regionID]) * 100;
      }
    });

    return percentage.toFixed(2) + "%";
  };

  const handleCardClick = (event, title) => {
    if (overviewLinks[title]) {
      props.history.push(overviewLinks[title]);
    }
  };

  return (
    <div className="overview-page-wrapper">
      <div className="overview-page-body-wrapper">
        <SideBar pageHistory={props.history} activeLink="/overview" />
        <div className="overview-page-body">
          <NavBar title="Overview" />
          {isLoading ? (
            <div className="overview-page-body-main overview-page-loader">
              <BlockLoading color="#FF4B30" />
              <p>Loading Stats ... </p>
            </div>
          ) : (
            <div className="overview-page-body-main">
              <div className="overview-page-body-main-fixed">
                <div className="overview-page-body-main-filters">
                  <Filters
                    handleRegionsFilter={(e) => handleRegionsFilter(e)}
                  />
                </div>
                <div className="overview-page-body-main-stats">
                  {overviewData.map((data, index) => {
                    return (
                      <TotalsCard
                        key={index}
                        linkedCard={overviewLinks[data.title] ? true : false}
                        style={
                          data.title === "Vendors"
                            ? {
                                border: "1px solid rgba(255, 75, 48, 0.5)",
                                color: "#ff4b30",
                              }
                            : {}
                        }
                        title={data.title}
                        region={vendorStatsRegions[overviewRegion]}
                        total={data[overviewRegion]}
                        percentage={
                          data.percentageParams
                            ? getPercentage(
                                data.percentageParams,
                                overviewRegion,
                                data[overviewRegion]
                              )
                            : null
                        }
                        handleCardClick={(e) => handleCardClick(e, data.title)}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OverviewPage;
