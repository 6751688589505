import React from "react";
import "./CategoryCard.css";

const CategoryCard = (props) => {
  return (
    <div
      className="category-card-wrapper"
      style={{
        backgroundColor:
          props.category.totalNumberOfCategoryItems === 0
            ? "#ffa494"
            : "#ffffff",
      }}
    >
      <h5 className="category-card-item-1">{props.category.region}</h5>
      <h5 className="category-card-item-2">{props.category.category}</h5>
      <h5 className="category-card-item-3">
        {props.category.totalNumberOfCategoryItems}
      </h5>
    </div>
  );
};

export default CategoryCard;
