import React from "react";
import "./MenuCard.css";

const MenuCard = (props) => {
  return (
    <div
      className="menu-card-wrapper"
      onClick={(e) => props.handleMenuCardClick(props.menu.link)}
    >
      <props.menu.icon className="menu-card-icon" />
      <div className="menu-card-texts">
        <h3>{props.menu.title}</h3>
        <p>{props.menu.text}</p>
      </div>
    </div>
  );
};

export default MenuCard;
