import React, { useState, useEffect } from "react";
import "./CategoriesPage.css";
import SideBar from "../SideBar/SideBar";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import Filters from "../Filters/Filters";
import fetchUtils from "../../Functions/fetchUtils";
import statsFilterRegions from "../../Data/statsFilterRegions";
import { BlockLoading } from "react-loadingg";
import CategoryCard from "./CategoryCard/CategoryCard";

const CategoriesPage = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [categoryData, setCategoryData] = useState([]);
  const [categoryRegion, setCategoryRegion] = useState("all");

  useEffect(() => {
    setIsLoading(true);

    getCategoryData("all");
  }, []);

  const getCategoryData = async (region) => {
    setIsLoading(true);
    setCategoryRegion(region);
    let response = await fetchUtils(
      "/statistics/category-data/" + region,
      "GET",
      ""
    );
    if (response.success) {
      console.log(response);
      setCategoryData(response.categoryData);
      setIsLoading(false);
    } else {
      console.log(response.message);
      setIsLoading(false);
    }
  };

  const handleRegionsFilter = (event) => {
    if (event.target.name === statsFilterRegions[categoryRegion]) {
      return;
    }

    switch (event.target.name) {
      case "All":
        getCategoryData("all");
        break;
      case "North America":
        getCategoryData("na");
        break;
      case "Europe & UK":
        getCategoryData("eu");
        break;
      default:
        getCategoryData("all");
    }
  };

  return (
    <div className="categories-page-wrapper">
      <div className="categories-page-body-wrapper">
        <SideBar pageHistory={props.history} activeLink="/categories" />
        <div className="categories-page-body">
          <NavBar title="Categories" />
          <div className="categories-page-body-main">
            <div className="categories-page-body-main-fixed">
              <div
                className="categories-page-body-main-filters"
                style={{ visibility: isLoading ? "hidden" : "inherit" }}
              >
                <Filters handleRegionsFilter={(e) => handleRegionsFilter(e)} />
              </div>
              <div className="categories-page-body-main-stats">
                <div className="categories-page-body-main-categories-details-header">
                  <h5 className="categories-header-item-1">region</h5>
                  <h5 className="categories-header-item-2">Category</h5>
                  <h5 className="categories-header-item-3">Total Items</h5>
                </div>
                {isLoading ? (
                  <div className="categories-page-body-main-categories-details-body categories-page-loader">
                    <BlockLoading color="#FF4B30" />
                    <p>Loading Data ... </p>
                  </div>
                ) : (
                  <div className="categories-page-body-main-categories-details-body">
                    <div className="categories-page-body-main-categories-details-body-fixed">
                      {categoryData.map((category, index) => {
                        return <CategoryCard key={index} category={category} />;
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CategoriesPage;
