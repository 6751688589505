import React, { useState, useEffect } from "react";
import "./DateFilterInput.css";
import Gap from "../../CommonComponents/Gap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { subDays } from "date-fns";
import Line from "../../CommonComponents/Line";
import copyOfArrayOrObject from "../../../Functions/copyOfArrayOrObject";

const DateFilterInput = (props) => {
  const [filterDateInput, setFilterDateInput] = useState(null);

  useEffect(() => {
    setFilterDateInput(props.filterDateRange);
  }, [props]);

  const handleDateInput = (date, type) => {
    let copyOfFilterDateInput = {};
    if (filterDateInput) {
      copyOfFilterDateInput = copyOfArrayOrObject(filterDateInput);
    }
    copyOfFilterDateInput[type] = date;
    setFilterDateInput(copyOfFilterDateInput);
    props.filterDateRange(copyOfFilterDateInput);
  };

  return (
    <div className="date-filter-input-wrapper">
      <div className="date-filter-input-dates">
        <DatePicker
          className="date-filter-input"
          placeholderText="From"
          selected={
            filterDateInput
              ? typeof filterDateInput.from === "string"
                ? Date.parse(filterDateInput.from)
                : filterDateInput.from
              : filterDateInput
          }
          onChange={(d) => handleDateInput(d, "from")}
        />
      </div>

      <Line height="100%" width="1px" color="#b8c5d3" />

      <div className="date-filter-input-dates">
        <DatePicker
          className="date-filter-input"
          placeholderText="To"
          selected={
            filterDateInput
              ? typeof filterDateInput.to === "string"
                ? Date.parse(filterDateInput.to)
                : filterDateInput.to
              : filterDateInput
          }
          minDate={
            filterDateInput &&
            filterDateInput.from &&
            subDays(new Date(filterDateInput.from), 0)
          }
          disabled={!(filterDateInput && filterDateInput.from)}
          onChange={(d) => handleDateInput(d, "to")}
        />
      </div>
    </div>
  );
};

export default DateFilterInput;
