const productFulhausFormat = [
  { key: "itemName", required: true, alt: [] },
  { key: "imageURLs", required: true, alt: [] },
  { key: "SKU", required: true, alt: [] },
  { key: "itemLink", required: false, alt: ["Assign 1st image URL"] },
  { key: "weight", required: false, alt: [0] },
  { key: "weightUnit", required: false, alt: ["lbs", "kg", "gr"] },
  { key: "length", required: false, alt: [0] },
  { key: "width", required: false, alt: [0] },
  { key: "height", required: false, alt: [0] },
  { key: "dimensionUnit", required: false, alt: ["in", "cm", "mm"] },
  { key: "tradePrice", required: true, alt: [] },
  {
    key: "MAP",
    required: false,
    alt: ["Compute as trade price with a markup of 1.5"],
  },
  {
    key: "MSRP",
    required: false,
    alt: ["Compute as trade price with a markup of 1.5"],
  },
  {
    key: "tradeCurrency",
    required: false,
    alt: ["USD", "CAD", "EUR", "GBP", "AUD"],
  },
  { key: "vendorProductCategory", required: false, alt: ["Assign Item Name"] },
  { key: "color", required: false, alt: ["Ignore"] },
  { key: "material", required: false, alt: ["Ignore"] },
  { key: "variants", required: false, alt: ["Ignore"] },
  { key: "casePackQty", required: false, alt: [1] },
  {
    key: "vendorItemDescription",
    required: false,
    alt: ["Assign Item Name"],
  },
  {
    key: "dimension",
    required: false,
    alt: ["Ignore"],
  },
];

export default productFulhausFormat;
