import React from "react";
import "./NavBar.css";
import Logo from "../../Logo/fulhaus-logo.png";
import { connect } from "react-redux";

const UnconnectedNavBar = (props) => {
  return (
    <div className="navbar-wrapper">
      <div className="navbar-left-section">
        <div
          className="navbar-logo"
          style={{ backgroundImage: "url(" + Logo + ")" }}
          onClick={(e) => props.pageHistory.push("/")}
        ></div>
        <h1>{props.title}</h1>
      </div>

      <div className="navbar-right-section">{props.componentOne}</div>
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
let NavBar = connect(mapStateToProps)(UnconnectedNavBar);

export default NavBar;
