import React from "react";
import "./PageNotFound.css";
import Button from "../CommonComponents/Button";

const PageNotFound = (props) => {
  const goToHomePage = (event) => {
    props.history.push("/");
  };

  return (
    <div className="page-not-found-wrapper">
      <h1>404</h1>
      <h5>Oops... You tried to access a page that does not exist</h5>
      <small>
        You may have mistyped the address, or the page has been moved
      </small>
      <Button
        name="submit"
        background="#ff4b30"
        borderRadius="calc(50px + 1vw)"
        width="calc(60px + 20vw)"
        enabled={true}
        height="calc(20px + 1vw)"
        padding="calc(2px + 0.2vw)"
        onClick={(e) => goToHomePage(e)}
        color="#ffffff"
        label="Take me to the home page"
        fontSize="calc(5px + 0.8vw)"
      />
    </div>
  );
};

export default PageNotFound;
