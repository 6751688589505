import React, { useState } from "react";
import "./ItemCard.css";
import DropDownList from "../../CommonComponents/DropDownList";
import ChevronDown from "../../../Icons/chevron_down.png";
import InputBox from "../../CommonComponents/InputBox";
import Gap from "../../CommonComponents/Gap";

const ItemCard = (props) => {
  const [alternateName, setAlternateName] = useState("");
  const [alternateNameError, setAlternateNameError] = useState(false);

  const handleAlternateName = (event) => {
    setAlternateNameError(false);
    if (event.target.value.split(" ").length < 2) {
      setAlternateNameError(true);
      setAlternateName(event.target.value);
      return;
    }

    setAlternateName(event.target.value);
    props.handleAlternateName(event);
  };

  return (
    <div className="item-card-wrapper">
      <div className="item-card-item-1">
        <div
          className="item-card-item-image"
          style={{ backgroundImage: "url(" + props.item.imageURLs[0] + ")" }}
        ></div>
        <h5>{props.item.itemName}</h5>
        <p>{props.item.vendorProductCategory}</p>
      </div>
      <div className="item-card-item-2">
        <p>Category</p>
        <DropDownList
          label="Select Category"
          labelFontSize="calc(14px + 0.2vw)"
          labelBackground="#F5F6FA"
          labelBorder="1px solid #DCE1EB"
          labelBorderRadius="5px"
          labelColor="#1f1f1f"
          labelAlign="space-between"
          labelHeight="40px"
          labelWidth="100%"
          labelPadding="0 calc(5px + 0.5vw)"
          listItems={props.categories}
          icon={ChevronDown}
          iconSize="10px"
          listAlign="left"
          listPadding=" 15px calc(8px + 1vw)"
          listMargin="0 0 calc(2px + 0.5vw) 0"
          listItemHoverBackground="#ff4b30"
          resetLabel={true}
          searchable={true}
          iconColorInvert={true}
          onSelect={(e) => props.handleSelectedCategory(e)}
        />
        <Gap value="10px" />
        <p>Alternate Name</p>
        <InputBox
          name="alternateName"
          type="text"
          background="#F5F6FA"
          border="1px solid #DCE1EB"
          borderRadius="5px"
          width="100%"
          height="40px"
          padding="0 calc(2px + 1vw)"
          margin="0"
          fontSize="calc(14px + 0.1vw)"
          placeholder="Enter an alternate name"
          value={alternateName}
          onChange={(e) => handleAlternateName(e)}
        />
        {alternateNameError && (
          <small className="item-card-alternate-error">
            Alternate name must be at least 2 words
          </small>
        )}
      </div>
      <div className="item-card-item-3"></div>
      <h5 className="item-card-item-4"></h5>
    </div>
  );
};

export default ItemCard;
