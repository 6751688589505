import React from "react";
import "./Menu.css";

const Menu = (props) => {
  const style = {
    menuWrapper: {
      backgroundColor: props.menu.link === props.activeLink && "#ffffff",
    },

    menuTitle: {
      opacity: props.menu.link === props.activeLink && 1,
    },
  };
  return (
    <div
      style={style.menuWrapper}
      className="menu-wrapper"
      onClick={(e) => props.handleMenuClick(props.menu.link)}
    >
      <props.menu.icon className="menu-icon" />
      <h3 style={style.menuTitle}>{props.menu.title}</h3>
    </div>
  );
};

export default Menu;
