import React from "react";
import "./SideBar.css";
import Logo from "../../Logo/fulhaus-logo.png";
import menus from "../../Data/menus";
import Menu from "./Menu/Menu";
import { connect } from "react-redux";

const UnconnectedSideBar = (props) => {
  const handleMenuClick = (link) => {
    props.pageHistory.push(link);
  };

  return (
    <div className="sidebar-wrapper">
      <div className="sidebar-top">
        <div
          className="sidebar-logo"
          style={{ backgroundImage: "url(" + Logo + ")" }}
          onClick={(e) => props.pageHistory.push("/")}
        ></div>
        <h3>Vendor Manager</h3>
      </div>
      <div className="sidebar-menus">
        {menus.map((menu, index) => {
          return (
            <Menu
              key={index}
              menu={menu}
              activeLink={props.activeLink}
              handleMenuClick={(l) => handleMenuClick(l)}
            />
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const SideBar = connect(mapStateToProps)(UnconnectedSideBar);

export default SideBar;
