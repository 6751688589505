import { FiBarChart } from "react-icons/fi";
import { FiLayers } from "react-icons/fi";
import { FiArrowUpCircle } from "react-icons/fi";
import { FiUsers } from "react-icons/fi";
import { FiShoppingBag } from "react-icons/fi";
import { FiGrid } from "react-icons/fi";

const menus = [
  {
    icon: FiBarChart,
    title: "Dashboard",
    text: "Graphical representation of activities",
    link: "/dashboard",
  },
  {
    icon: FiLayers,
    title: "Overview",
    text: "See vendor stats, items count, etc..",
    link: "/overview",
  },
  {
    icon: FiArrowUpCircle,
    title: "Onboarding",
    text: "Onboard new vendors, and map vendor product data",
    link: "/onboarding",
  },
  {
    icon: FiUsers,
    title: "Vendors",
    text: "All vendors, by region and item categories",
    link: "/vendors",
  },
  {
    icon: FiShoppingBag,
    title: "Items",
    text:
      "Search items by various parameters and map uncategorized items to a category",
    link: "/items",
  },
  {
    icon: FiGrid,
    title: "Categories",
    text: "Categories stats by items and region",
    link: "/categories",
  },
  {
    icon: FiGrid,
    title: "Sync Vendor Details",
    text: "Add vendor details to MongoDB",
    link: "/sync-vendor-details",
  },
];

export default menus;
