import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <div className="footer-wrapper">
      <p>© Fulhaus Inc {new Date().getFullYear()}, All Rights Reserved</p>
    </div>
  );
}
